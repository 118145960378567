// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-menu.dropdown-keep-visible {
    right: 5px;
    left: auto;
    position: absolute;
  }

  .notification-keep-visible{
    right: 0;
    left: auto;
    position: absolute;
  }
  
  .notification-message.unread {
    background-color: #e0f7fa; /* Light blue color for unread notifications */
    border-left: 4px solid #0288d1; /* Blue border to indicate unread notification */
  }
  
  .notification-message.unread:hover {
    background-color: #b3e5fc; /* Slightly darker blue on hover */
  }
  
  .notification-message {
    transition: background-color 0.3s ease-in-out; /* Smooth transition for background change */
  }
  
.btn-close {
  background: none;
  border: none;
  font-size: 1.25rem;
  cursor: pointer;
  color: rgb(94, 93, 93);
}

.btn-close:hover {
  color: #d00000; /* Darker red for hover state */
  border:none;
}
`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,UAAU;IACV,kBAAkB;EACpB;;EAEA;IACE,QAAQ;IACR,UAAU;IACV,kBAAkB;EACpB;;EAEA;IACE,yBAAyB,EAAE,8CAA8C;IACzE,8BAA8B,EAAE,gDAAgD;EAClF;;EAEA;IACE,yBAAyB,EAAE,kCAAkC;EAC/D;;EAEA;IACE,6CAA6C,EAAE,4CAA4C;EAC7F;;AAEF;EACE,gBAAgB;EAChB,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,sBAAsB;AACxB;;AAEA;EACE,cAAc,EAAE,+BAA+B;EAC/C,WAAW;AACb","sourcesContent":[".dropdown-menu.dropdown-keep-visible {\n    right: 5px;\n    left: auto;\n    position: absolute;\n  }\n\n  .notification-keep-visible{\n    right: 0;\n    left: auto;\n    position: absolute;\n  }\n  \n  .notification-message.unread {\n    background-color: #e0f7fa; /* Light blue color for unread notifications */\n    border-left: 4px solid #0288d1; /* Blue border to indicate unread notification */\n  }\n  \n  .notification-message.unread:hover {\n    background-color: #b3e5fc; /* Slightly darker blue on hover */\n  }\n  \n  .notification-message {\n    transition: background-color 0.3s ease-in-out; /* Smooth transition for background change */\n  }\n  \n.btn-close {\n  background: none;\n  border: none;\n  font-size: 1.25rem;\n  cursor: pointer;\n  color: rgb(94, 93, 93);\n}\n\n.btn-close:hover {\n  color: #d00000; /* Darker red for hover state */\n  border:none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
