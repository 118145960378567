// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f8f9fa; /* Optional background */
}

.spinner-text {
  margin-top: 15px;
  font-size: 18px;
  font-weight: bold;
  color: #007bff;
}
`, "",{"version":3,"sources":["webpack://./src/components/Spinner/Spinner.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,yBAAyB,EAAE,wBAAwB;AACrD;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,cAAc;AAChB","sourcesContent":[".spinner-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  height: 100vh;\n  background-color: #f8f9fa; /* Optional background */\n}\n\n.spinner-text {\n  margin-top: 15px;\n  font-size: 18px;\n  font-weight: bold;\n  color: #007bff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
